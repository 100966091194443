import React from "react";
import { createRoot } from "react-dom/client";
import { Theme } from "@radix-ui/themes";
import { Main } from "./main";
import "@radix-ui/themes/styles.css";
import "./main.css";
// import "./index.css";

const container = document.querySelector("#root");
const root = createRoot(container);

root.render(
    <Theme accentColor="teal" hasBackground={false}>
      <Main />
      {/* <ThemePanel /> */}
    </Theme>
);
