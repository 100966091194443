import {
  Flex,
  Text,
  Button,
  Card,
  Box,
  Avatar,
  Link,
  DataList,
} from "@radix-ui/themes";
import { Items } from "./data";
import { useState } from "react";
import * as emoji from "node-emoji";

type Price = {
  label: string;
  amount: number;
};
type Item = {
  url: string;
  no: number;
  productNameSpan: string;
  productQuantitySpan: string;
  basePrice: Price;
  salePrice: Price;
};

const style = {
  background: "linear-gradient(45deg, #00bcf9, #0EB39E 70%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
  color: "transparent",
};

export function Main() {
  const MAX_PRICE = 1800;
  const [displayItems, setDisplayItems] = useState<Item[]>([]);
  function click() {
    const shuffledItems = [...Items]
      .filter((e) => e.salePrice.amount <= MAX_PRICE)
      .sort(() => 0.5 - Math.random());
    let totalAmount = 0;
    let selectedItems = [];
    for (let item of shuffledItems) {
      if (totalAmount + item.salePrice.amount <= MAX_PRICE) {
        selectedItems.push(item);
        totalAmount += item.salePrice.amount;
      }
    }
    setDisplayItems(selectedItems);
  }

  return (
    <Flex direction="column" gap="6" align="center">
      <Flex gap="2" pt="6" align="center">
        <Text as="span" size="8" weight="bold">
          家庭用常備薬
        </Text>
        <Text as="span" size="8" weight="bold" style={style}>
          補助斡旋
        </Text>
        <Text as="span" size="8" weight="bold">
          ガシャ
        </Text>
      </Flex>
      <Box>
        <Flex align="start" direction="column">
          <Text as="span" size="3">
            1,800円まで補助がでるので、ランダムに選出します
          </Text>
          <Flex align="start">
            <Link href="https://www.blancnetplus.com/h1/a/tkfudousan" target="_blank" rel="noopener noreferrer" color="indigo" weight="bold">
              ログイン
            </Link>
            <Text as="span" size="3">
              はこちら
            </Text>
          </Flex>
        </Flex>
      </Box>
      <Flex width="500px" gap="6" align="center" justify="between">
        <DataList.Root size="3" trim="start">
          <DataList.Item>
            <DataList.Label minWidth="88px">商品点数</DataList.Label>
            <DataList.Value>{displayItems.length}点</DataList.Value>
          </DataList.Item>
          <DataList.Item>
            <DataList.Label minWidth="88px">合計金額</DataList.Label>
            <DataList.Value>
              {displayItems
                .reduce((accumulator, product) => {
                  return accumulator + product.salePrice.amount;
                }, 0)
                .toLocaleString()}
              円
            </DataList.Value>
          </DataList.Item>
        </DataList.Root>
        <Button
          id="mainButton"
          size="3"
          color="gray"
          onClick={click}
          style={{
            background: "#111111",
            boxShadow:
              "0 0 15px rgba(212, 175, 55, 1), 0 0 15px rgba(100, 150, 255, 0.3),0 0 20px rgba(255, 255, 255, 1)",
            transition: "opacity .2s ease-in-out",
          }}
        >
          Let's Go!
        </Button>
      </Flex>
      <Box width="500px">
        <Flex direction="column" gap="4">
          {displayItems.map((i) => {
            return (
              <Link
                href={i.url}
                key={`${i.no}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Card size="3">
                  <Flex gap="4" align="center" justify="between">
                    <Flex gap="6" align="center">
                      <Avatar
                        size="5"
                        radius="full"
                        fallback={<Text size="8">{emoji.random().emoji}</Text>}
                        color="indigo"
                      />
                      <Box>
                        <Text as="div" size="4" weight="bold">
                          {i.productNameSpan}
                        </Text>
                        <Text as="div" size="4" color="gray">
                          {i.productQuantitySpan}
                        </Text>
                      </Box>
                    </Flex>
                    <Box>
                      <Text as="div" size="4" weight="bold">
                        {i.salePrice.label}
                      </Text>
                    </Box>
                  </Flex>
                </Card>
              </Link>
            );
          })}
        </Flex>
      </Box>
    </Flex>
  );
}
